import { NbMenuItem } from '@nebular/theme';

export const ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    home: true,
    link: '/',
  },
  {
    title: 'What I do',
    icon: 'briefcase-outline',
    children: [
      {
        title: 'Tech Stack',
        link: '/tech',
      },
      {
        title: 'Consulting',
        link: '/tech/consulting',
      },
      {
        title: 'Training',
        link: '/tech/training',
      },
    ],
  },
  {
    title: 'blog',
    icon: 'file-text-outline',
    link: '/blog',
  },
  {
    title: 'Contact me',
    icon: 'email-outline',
    link: '/contact',
  },
];
