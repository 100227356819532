import { Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { ITEMS } from './app.module.data';

@Component({
  selector: 'rp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'RenatoPerezDev';
  items: NbMenuItem[] = ITEMS;
  constructor() {}
}
